import React from 'react';
import searchIcon from "../../components/assets/img/icons/ic-magnify.png";

const ToolBar = (props) => {
  const {
    handleSearch,        
} = props;
  return (
    <div className="tool-bar container-60">      
      <input aria-label="searchInput" type="text" onKeyUp={(el) => handleSearch(el.target.value)} placeholder="Pesquisar" style={{ background: `url(${searchIcon}) no-repeat top 6px right 10px/20px` }} />
      {/* <span>
          <label htmlFor="sort-select">Organizar por</label>
          <select style={{ marginRight: 25, }} name="sort-select" id="sort-select">
              <option value={1}>Lorem ipsum</option>
              <option value={2}>Ipsum lorem</option>
              <option value={3}>Losum iprem</option>
          </select>
          <label htmlFor="filter-select">Apenas em</label>
          <select name="filter-select" id="filter-select">
              <option value={1}>Lorem ipsum</option>
              <option value={2}>Ipsum lorem</option>
              <option value={3}>Losum iprem</option>
          </select>
      </span> */}
    </div>
  )
}

export default ToolBar;
